<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import type { Ref } from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  canClose: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['close'])

const modal: Ref<HTMLDialogElement | null> = ref(null)

function open() {
  modal.value?.showModal()
}

function close() {
  emit('close')
  modal.value?.close()
}

watch([props], () => {
  // closing first to prevent error
  close()

  if (props.show)
    open()
})

function backdropClickCheck(event: MouseEvent) {
  if (!props.canClose)
    return

  const target = event.target as HTMLElement
  const rect = target.getBoundingClientRect()

  if (rect.left > event.clientX
    || rect.right < event.clientX
    || rect.top > event.clientY
    || rect.bottom < event.clientY
  )
    close()
}

onMounted(() => {
  if (props.show)
    open()
})
</script>

<template>
  <div>
    <dialog ref="modal" class="bg-transparent" @click="backdropClickCheck">
      <div class="flex flex-col pr-8">
        <button v-if="canClose" class="pointer-event-auto -mr-8 text-white self-end outline-none" @click="close">
          <ClientOnly>
            <Icon name="heroicons:x-mark" class="w-8 h-8" />
          </ClientOnly>
        </button>
        <slot :close="close" />
      </div>
    </dialog>
  </div>
</template>

<style scoped>
dialog::backdrop {
  @apply cursor-pointer;
  background-color: rgba(0,0,0,0.5);
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  @apply translate-y-4 scale-90;
  opacity: 0;
}
</style>
